import React, { useContext, useEffect, useState } from "react";
import {
  Card,
  Container,
  Row,
  Col,
  Button,
  Form,
  OverlayTrigger,
  Tooltip,
  CardGroup,
  CardColumns,
} from "react-bootstrap";
import { Info } from "react-feather";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import { URL } from "../../../constants/url";
import NotyfContext from "../../../contexts/NotyfContext";
import * as Api from "../../../helper/api";
import { useAuth0 } from "@auth0/auth0-react";
import { Formik } from "formik";
import Loader from "../../../components/Loader";
import * as Yup from "yup";
import googleLight from "../../../assets/img/brands/google/2x/btn_google_signin_light_normal_web@2x.png";
import googleDark from "../../../assets/img/brands/google/2x/btn_google_signin_dark_normal_web@2x.png";
import {
  handleBaseScopes,
  handleModifyScope,
  handleSendScope,
  handleFullScopes,
} from "../../../utils/auth0";

/** dark / light buttons are hard. do dark */
function EmailFilter() {
  const notyf = useContext(NotyfContext);
  const userState = useSelector((state) => state.Auth);
  const customerState = useSelector((state) => state.Customer);
  const { loginWithRedirect } = useAuth0();
  const [loading, setLoading] = useState(false);
  const ValidationSchema = Yup.object().shape({});
  const userPrefs = useSelector((state) => customerState.info.preferences);
  // const orgSubscription = useSelector(
  //   (state) => customerState.info.subscriptionType
  // );

  useEffect(() => {
    if (customerState.info && customerState.info.emailVerified) {
      console.log("email is verified: " + customerState.info.emailVerified);
      getInfo();
    } else {
      console.log("email verified status " + customerState.info.emailVerified);
    }

    async function getInfo() {
      let requestData = {};
      let userId = customerState.info.authId;
      requestData["auth0Id"] = userId;
      try {
        await Api.postDataSecure(URL.updateUser, userState.token, requestData);
      } catch (exception) {
        notyf.open({ type: "error", message: "Unable to fetch user Info." });
      }
    }
  }, [notyf, userState.token, userState.userId]);

  const handleUserPrefUpdate = async (value, action) => {
    let requestData = {};
    requestData["auth0Id"] = customerState.info.authId;
    requestData["minOfferRequired"] = value.minOfferRequired;
    requestData["maxOfferAllowed"] = value.maxDepositAllowed;
    requestData["minDepositRequired"] = value.minDepositRequired;
    requestData["maxDepositAllowed"] = value.maxDepositAllowed;
    requestData["daysDeadlineDeposit"] = value.daysDeadlineDeposit;
    requestData["autoResponseEmail"] = value.autoResponseEmail;
    requestData["checkGmailInbox"] = value.checkGmailInbox;
    requestData["checkGmailPromotions"] = value.checkGmailPromotions;
    requestData["checkGmailSpam"] = value.checkGmailSpam;
    // requestData["urlLinkedin"] = value.urlLinkedin;
    // requestData["urlFacebook"] = value.urlFacebook;
    // requestData["urlTwitter"] = value.urlTwitter;
    // requestData["urlInstagram"] = value.urlInstagram;
    setLoading(true);
    try {
      await Api.postDataSecure(
        URL.updateUserPrefs,
        userState.token,
        requestData
      );
      // dispatch(getCustomerInfo(customerState.info.auth0Id)); // returns Json of Json. Should always be used after updating a customer or organization related object
      setLoading(false);
      notyf.open({
        type: "success",
        message: "Successfully updated user preferences",
      });
    } catch (exception) {
      notyf.open({
        type: "error",
        message: "Unable to update user preferences.",
      });
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      <Helmet title="Email Filter" />
      <Container fluid className="p-0 my-3">
        <Card>
          <Card.Header>
            <Card.Title tag="h5" className="mb-0">
              FynCom Filters: Email Edition
            </Card.Title>
            <br></br>
            <Card.Subtitle> Get paid to block spam emails </Card.Subtitle>
          </Card.Header>
          <Card.Body>
            <Card.Text>
              Sick of unwanted emails, but don't want to miss out on genuine
              messages? FynCom gives you a whitelist of your contacts and lets
              everyone else whitelist themselves with a refundable deposit.
              <br></br>Non-contacts get their deposit back when you respond
              (FynMail). Businesses can just reward you to respond (FynReward).
              <br></br>
              {/* <hr></hr> */}
              <b>Your minimum starts at $0.00001.</b> <br></br>Anyone willing to
              offer you a deposit or reward of $0.00001, will get into your main
              Inbox.*
              <br></br>
              <b>
                Bonus: You get paid $0.00001 for every blocked email!{" "}
                <Card.Link href="https://www.fyncom.com/fyncom-filters-email-edition">
                  Read more here
                </Card.Link>
                .
              </b>
              <br></br> *You can change your minimums as a paid subscriber.
            </Card.Text>
            <Row>
              {/* <Card.Text>
                It's true, we've gotten so fed up with this, we've gone ahead
                and built it for ourselves
                <a href="https://www.fyncom.com/fyncom-filters-email-edition">
                  . Read more here
                </a>
                .
              </Card.Text>
              <p style={{ fontSize: 13 }}>
                When you're ready, hit the "Sign in with Google" button below to
                start.
              </p> */}
            </Row>
            <Row>
              <Col className="border-red p-3">
                <div className="d-flex flex-column justify-content-between h-100">
                  <Card.Title tag="h5" className="mb-0">
                    Full Access Experience
                  </Card.Title>
                  <Card.Text>
                    <br></br>Grants complete access to manage your inbox,
                    including the ability to delete unwanted emails. Uses
                    Gmail's modify permission which allows us to read, modify,
                    and delete messages for comprehensive email filtering.
                  </Card.Text>
                  <Row className="p-3">
                    <button
                      className="google-button mt-auto"
                      onClick={() => handleFullScopes(loginWithRedirect)}
                    >
                      <img
                        className="google-image"
                        src={googleDark}
                        alt="Google sign-in"
                      />
                    </button>
                  </Row>
                </div>
              </Col>
              <Col className="border-blue p-3">
                <div className="d-flex flex-column justify-content-between h-100">
                  <Card.Title tag="h5" className="mb-0">
                    Best Experience
                  </Card.Title>
                  <Card.Text>
                    <br></br>Lets us apply labels to incoming emails for you and
                    respond to them. We do not want or need read permissions,
                    but the only way to move emails from your Inbox is with the
                    gmail.modify permission, which comes with read/send
                    permissions.
                  </Card.Text>
                  <Row className="p-3">
                    <button
                      className="google-button mt-auto"
                      onClick={() => handleModifyScope(loginWithRedirect)}
                    >
                      <img
                        className="google-image"
                        src={googleDark}
                        alt="Google sign-in"
                      />
                    </button>
                  </Row>
                </div>
              </Col>
              <Col className="border-green p-3">
                <div className="d-flex flex-column justify-content-between h-100">
                  <Card.Title tag="h5" className="mb-0">
                    Privacy-focused Experience
                  </Card.Title>
                  Read permissions are not granted here. However, we cannot
                  apply labels or move emails for you. We do ask for send
                  permission, which lets us send "PayCation" emails to people to
                  let them know they should submit a refundable deposit to
                  contact you.
                  <Row className="p-3">
                    <button
                      className="google-button mt-auto"
                      onClick={() => handleSendScope(loginWithRedirect)}
                    >
                      <img
                        className="google-image"
                        src={googleDark}
                        alt="Google sign-in"
                      />
                    </button>
                  </Row>
                </div>
              </Col>
              {/* <Col className="border-red p-3">
                <div className="d-flex flex-column justify-content-between h-100">
                  Bare minimum experience. No sends, no label movement, only
                  rewards. Unlikely to 
                  <Row className="p-3">
                    <button
                      className="google-button mt-auto" // "mt-auto" is Bootstrap class for "margin-top: auto"
                      onClick={() => handleBaseScopes(loginWithRedirect)}
                    >
                      <img
                        className="google-image"
                        src={googleDark}
                        alt="Google sign-in"
                      />
                    </button>
                  </Row>
                </div>
              </Col> */}
            </Row>
            <Row>
              <Card.Text>
                <br></br>
                <b>FynCom Filters for emails</b> rewards you for blocking
                untrusted emails and requires unknown senders to offer
                refundable deposits for primary inbox access. <br></br>Our use
                and transfer of information received from Google APIs will
                adhere to the{" "}
                <Card.Link href="https://developers.google.com/terms/api-services-user-data-policy">
                  Google API Services User Data Policy
                </Card.Link>
                , including the{" "}
                <a href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes">
                  Limited Use requirements
                </a>
                . <br></br>FynCom requires tkhese 3 Gmail permissions:{" "}
                <Card.Link href="https://www.googleapis.com/auth/gmail.settings.basic">
                  settings.basic
                </Card.Link>
                ,{" "}
                <a href="https://www.googleapis.com/auth/gmail.metadata">
                  metadata
                </a>
                ,{" "}
                <a href="https://www.googleapis.com/auth/gmail.labels">
                  labels
                </a>
                , and 1 of these 2:{" "}
                <a href="https://www.googleapis.com/auth/gmail.modify">
                  modify
                </a>{" "}
                or <a href="https://www.googleapis.com/auth/gmail.send">send</a>
                . <br></br>Thanks for helping us create the world's trusted
                communications network with economic incentives!
                <br></br>
              </Card.Text>
              <br></br>
              <p>
                Reach out to us anytime for help.{" "}
                <a href="mailto:support@fyncom.com?subject=App Dashboard help">
                  Email us
                </a>{" "}
                or reach us on Twitter{" "}
                <a href="https://twitter.com/Fyn_Com">@Fyn_Com</a>.
              </p>
            </Row>
          </Card.Body>
        </Card>
      </Container>
      <hr></hr>
      {/* Start of FynMail Card. This permission container should be shown first, after the user has opted-in */}
      <Container fluid className="p-0 my-3">
        <Card>
          <Card.Header>
            <Card.Title tag="h5" className="mb-0">
              FynMail Preferences - Inbound Only
            </Card.Title>
            <br></br>
            <Card.Subtitle>
              Set minimum deposits, deadlines, and auto-responders
            </Card.Subtitle>
          </Card.Header>
          <Card.Body>
            {/* Turn the selective editing back on when ready to check subscription status */}
            {/* {orgSubscription === "FREE" ? (
              <div className="alert alert-info">
                Only paid plans can update these. Please upgrade if you want to
                update the rewards notification email that your respondants get.
              </div>
            ) : null} */}
            {loading && <Loader />}
            <Formik
              // org prefs should always show FynCom or Customer's depending on subscription type
              initialValues={{
                minDepositRequired: userPrefs
                  ? userPrefs.minDepositRequired
                  : "0.00001",
                daysDeadlineDeposit: userPrefs
                  ? userPrefs.daysDeadlineDeposit
                  : 30,
                autoResponseEmail: userPrefs
                  ? userPrefs.autoResponseEmail
                  : true,
                checkGmailInbox: userPrefs ? userPrefs.checkGmailInbox : true,
                checkGmailPromotions: userPrefs
                  ? userPrefs.checkGmailPromotions
                  : false,
                checkGmailSpam: userPrefs ? userPrefs.checkGmailSpam : false,
              }}
              validationSchema={ValidationSchema}
              onSubmit={(values, action) => {
                handleUserPrefUpdate(values, action);
              }}
            >
              {(formikProps) => {
                const {
                  values,
                  errors,
                  touched,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isValid,
                  setFieldValue,
                } = formikProps;
                return (
                  <Form className="mx-0" onSubmit={handleSubmit}>
                    <Card.Text>
                      A FynMail operates on a trust-based system. Friends,
                      family, colleagues, and others can send you a FynMail with
                      a fully refundable deposit. This deposit signifies their
                      trust in you to respond within a specified deadline. If
                      you respond before the deadline, the sender is immediately
                      refunded their deposit in full. If not, you keep the
                      deposit. Use the settings below to set the minimum FynMail
                      values required for emails to land in your primary inbox.{" "}
                      <br></br>
                    </Card.Text>
                    <Row>
                      <Col md="4">
                        {/* For FynMail Minimum Value */}
                        <Form.Group className="mb-3">
                          <br></br>
                          <Form.Label>
                            <b>FynMail Value.</b> Minimum Refundable Deposit
                            Required ($) &nbsp;
                            <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <Tooltip id="tooltip-help">
                                  Set the minimum deposit a sender must offer in
                                  order to land in your primary inbox as a
                                  FynMail.
                                </Tooltip>
                              }
                            >
                              <Info size="15" className="recharge-info__icon" />
                            </OverlayTrigger>
                          </Form.Label>
                          <Form.Control
                            id="minDepositRequired"
                            type="text"
                            name="minDepositRequired"
                            isInvalid={
                              touched.minDepositRequired &&
                              !!errors.minDepositRequired
                            }
                            value={values.minDepositRequired}
                            placeholder="Min deposit required for an email to appear in your inbox."
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.minDepositRequired}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col md="4">
                        {/* For FynMail Dates Deadline */}
                        <Form.Group className="mb-3">
                          <br></br>
                          <Form.Label>
                            <b>FynMail Time.</b> Deadline before sender's
                            deposit expires (days) &nbsp;
                            <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <Tooltip id="tooltip-help">
                                  Set the deadline, in days. If you respond to a
                                  FynMail before this number of days, the sender
                                  gets a full & immediate refund of their
                                  deposit. Otherwise, you keep their deposit.
                                </Tooltip>
                              }
                            >
                              <Info size="15" className="recharge-info__icon" />
                            </OverlayTrigger>
                          </Form.Label>
                          <Form.Control
                            id="daysDeadlineDeposit"
                            type="number"
                            name="daysDeadlineDeposit"
                            isInvalid={
                              touched.daysDeadlineDeposit &&
                              !!errors.daysDeadlineDeposit
                            }
                            value={values.daysDeadlineDeposit}
                            placeholder="Number of days before a deposit expires."
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.daysDeadlineDeposit}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col md="4">
                        {/* For FynMail AutoResponder Email */}
                        <Form.Group className="mb-3">
                          <br></br>
                          <Form.Check>
                            <b>FynFilter Update.</b> Automated email response to
                            update senders &nbsp;
                            <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <Tooltip id="tooltip-help">
                                  When someone is sent to the FynFiltered inbox,
                                  their email receives an automated email
                                  response, letting them know how they can get
                                  in touch with you. Your referral link is
                                  included. This happens once per email thread.
                                  You can turn this off if you have a paid
                                  account.
                                </Tooltip>
                              }
                            >
                              <Info size="15" className="recharge-info__icon" />
                            </OverlayTrigger>
                            <br></br>
                            <Form.Check.Input
                              id="autoResponseEmail"
                              type="checkbox"
                              name="autoResponseEmail"
                              isInvalid={
                                touched.autoResponseEmail &&
                                !!errors.autoResponseEmail
                              }
                              checked={values.autoResponseEmail}
                              onChange={(e) => {
                                setFieldValue(
                                  "autoResponseEmail",
                                  e.target.checked
                                );
                              }}
                              onBlur={handleBlur}
                            />
                            <Form.Check.Label></Form.Check.Label>
                            <Form.Control.Feedback type="invalid">
                              {errors.autoResponseEmail}
                            </Form.Control.Feedback>
                          </Form.Check>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="4">
                        {/* For Email Inbox Filter On/Off */}
                        <Form.Group className="mb-3">
                          <br></br>
                          <Form.Check>
                            <b> Inbox.</b> Filter your main inbox? &nbsp;
                            <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <Tooltip id="tooltip-help">
                                  Turn inbox filtering on/off by checking or
                                  unchecking this box.
                                </Tooltip>
                              }
                            >
                              <Info size="15" className="recharge-info__icon" />
                            </OverlayTrigger>
                            <br></br>
                            <Form.Check.Input
                              id="checkGmailInbox"
                              type="checkbox"
                              name="checkGmailInbox"
                              isInvalid={
                                touched.checkGmailInbox &&
                                !!errors.checkGmailInbox
                              }
                              checked={values.checkGmailInbox || true}
                              onChange={(e) => {
                                setFieldValue(
                                  "checkGmailInbox",
                                  e.target.checked
                                );
                              }}
                              onBlur={handleBlur}
                            />
                            <Form.Check.Label></Form.Check.Label>
                            <Form.Control.Feedback type="invalid">
                              {errors.checkGmailInbox}
                            </Form.Control.Feedback>
                          </Form.Check>
                        </Form.Group>
                      </Col>
                      <Col md="4">
                        {/* For turning Promotions Email check On/Off */}
                        <Form.Group className="mb-3">
                          <br></br>
                          <Form.Check>
                            <b>Promotions.</b> Filter Promotions? &nbsp;
                            <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <Tooltip id="tooltip-help">
                                  Turn filtering of your promotions inbox on/off
                                  here.
                                </Tooltip>
                              }
                            >
                              <Info size="15" className="recharge-info__icon" />
                            </OverlayTrigger>
                            <br></br>
                            <Form.Check.Input
                              id="checkGmailPromotions"
                              type="checkbox"
                              name="checkGmailPromotions"
                              isInvalid={
                                touched.checkGmailPromotions &&
                                !!errors.checkGmailPromotions
                              }
                              checked={values.checkGmailPromotions || false}
                              onChange={(e) => {
                                setFieldValue(
                                  "checkGmailPromotions",
                                  e.target.checked
                                );
                              }}
                              onBlur={handleBlur}
                            />
                            <Form.Check.Label></Form.Check.Label>
                            <Form.Control.Feedback type="invalid">
                              {errors.checkGmailPromotions}
                            </Form.Control.Feedback>
                          </Form.Check>
                        </Form.Group>
                      </Col>
                      <Col md="4">
                        {/* For turning Spam Email check On / Off */}
                        <Form.Group className="mb-3">
                          <br></br>
                          <Form.Check>
                            <b>Spam.</b> Filter Spam? &nbsp;
                            <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <Tooltip id="tooltip-help">
                                  This is an alpha feature we are testing. You
                                  will not be able to update this. Normally, we
                                  only check the main inbox.
                                </Tooltip>
                              }
                            >
                              <Info size="15" className="recharge-info__icon" />
                            </OverlayTrigger>
                            <br></br>
                            <Form.Check.Input
                              id="checkGmailSpam"
                              type="checkbox"
                              name="checkGmailSpam"
                              isInvalid={
                                touched.checkGmailSpam &&
                                !!errors.checkGmailSpam
                              }
                              checked={values.checkGmailSpam || false}
                              onChange={(e) => {
                                setFieldValue(
                                  "checkGmailSpam",
                                  e.target.checked
                                );
                              }}
                              onBlur={handleBlur}
                            />
                            <Form.Check.Label></Form.Check.Label>
                            <Form.Control.Feedback type="invalid">
                              {errors.checkGmailSpam}
                            </Form.Control.Feedback>
                          </Form.Check>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Button
                          variant="primary"
                          type="submit"
                          className="mt-2 mr-1 w-100 user-prefs-save-button"
                          disabled={!isValid}
                        >
                          Save FynMail Preferences
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                );
              }}
            </Formik>
          </Card.Body>
        </Card>
      </Container>

      <hr></hr>
      <Card className="form-control-readonly">
        <Card.Header>
          <Card.Title tag="h5" className="mb-0">
            FynRewards Preferences - Inbound Only
          </Card.Title>
          <br></br>
          <Card.Subtitle>
            Set the minimum reward a business must offer you
          </Card.Subtitle>
        </Card.Header>
        <Card.Body>
          {loading && <Loader />}
          <Formik
            initialValues={{
              minOfferRequired: userPrefs
                ? userPrefs.minOfferRequired
                : "0.00001",
            }}
            validationSchema={ValidationSchema}
            onSubmit={(values, action) => {
              handleUserPrefUpdate(values, action);
            }}
          >
            {(formikProps) => {
              const {
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                isValid,
              } = formikProps;
              return (
                <Form className="mx-0" onSubmit={handleSubmit}>
                  <Card.Text>
                    A FynReward is a transactional incentive offered by
                    businesses and individuals with a commercial interest in
                    your response. By responding, you receive an immediate
                    reward - the value of which is determined by the sender and
                    can range from small to substantial amounts based on their
                    maximum offer to you. The sender also sets the expiration
                    deadline for the reward. Use the settings below to determine
                    the minimum FynReward values required for such emails to
                    land in your primary inbox. <br></br>
                  </Card.Text>
                  <Row>
                    <Col md="12">
                      {/* For Minimum Offers" */}
                      <Form.Group className="mb-3 form-control-readonly">
                        <Form.Label>
                          <b>FynReward Offer.</b> Minimum Reward Required ($)
                          &nbsp;
                        </Form.Label>
                        <OverlayTrigger
                          placement="bottom"
                          overlay={
                            <Tooltip id="tooltip-help">
                              Set the minimum REWARD a sender must offer in
                              order to land in your primary inbox as a
                              "FynReward".
                            </Tooltip>
                          }
                        >
                          <Info size="15" className="recharge-info__icon" />
                        </OverlayTrigger>
                        <Form.Control
                          id="minOfferRequired"
                          type="text"
                          name="minOfferRequired"
                          isInvalid={
                            touched.minOfferRequired &&
                            !!errors.minOfferRequired
                          }
                          value={values.minOfferRequired}
                          placeholder="Enter the minimum reward offer required."
                          readOnly={true}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.minOfferRequired}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  {/* <Row>
                    <Col>
                      <Button
                        variant="primary"
                        type="submit"
                        className="mt-2 mr-1 w-100 user-prefs-save-button"
                        disabled={true}
                      >
                        Save Preferences
                      </Button>
                    </Col>
                  </Row> */}
                </Form>
              );
            }}
          </Formik>
        </Card.Body>
      </Card>

      <hr></hr>
      <Card className="form-control-readonly">
        <Card.Header>
          <Card.Title tag="h5" className="mb-0">
            Outbound FynMail / FynRewards Preferences
          </Card.Title>
          <br></br>
          <Card.Subtitle>For Outbound only. Business Accounts</Card.Subtitle>
        </Card.Header>
        <Card.Body>
          {loading && <Loader />}
          <Formik
            initialValues={{}}
            validationSchema={ValidationSchema}
            onSubmit={(values, action) => {
              handleUserPrefUpdate(values, action);
            }}
          >
            {(formikProps) => {
              const {
                values,
                errors,
                touched,
                handleBlur,
                handleChange,
                handleSubmit,
                isValid,
              } = formikProps;
              return (
                <Form className="mx-0" onSubmit={handleSubmit}>
                  <Card.Text>
                    Set the max values to include with your outbound emails. If
                    your Max value is greater than your recipient's minimum
                    value, the recipient's minimum value will be used.
                  </Card.Text>
                  <Row>
                    <Col md="12">
                      {/* For Maximuim Deposits */}
                      <Form.Group className="mb-3 form-control-readonly">
                        <b>Outbound.</b>{" "}
                        <Form.Label>Max Refundable Deposit ($)</Form.Label>
                        <Form.Control
                          id="maxDepositAllowed"
                          type="text"
                          name="maxDepositAllowed"
                          isInvalid={
                            touched.maxDepositAllowed &&
                            !!errors.maxDepositAllowed
                          }
                          value={values.maxDepositAllowed}
                          placeholder="Enter max deposit you will risk for your emails to appear in an inbox."
                          readOnly={true}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.maxDepositAllowed}
                        </Form.Control.Feedback>
                      </Form.Group>
                      {/* For Maximuim Reward Offers */}
                      <Form.Group className="mb-3 form-control-readonly">
                        <b>Outbound.</b>{" "}
                        <Form.Label>Max Reward Offer Allowed ($)</Form.Label>
                        <Form.Control
                          id="maxOfferAllowed"
                          type="text"
                          name="maxOfferAllowed"
                          isInvalid={
                            touched.maxOfferAllowed && !!errors.maxOfferAllowed
                          }
                          value={values.maxOfferAllowed}
                          placeholder="Enter the maximum offer allowed."
                          readOnly={true}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.maxOfferAllowed}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                </Form>
              );
            }}
          </Formik>
        </Card.Body>
      </Card>
    </React.Fragment>
  );
}

export default EmailFilter;
